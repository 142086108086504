import React, { useContext, useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from 'urql'
import { useTranslation } from 'react-i18next-new'
import UserContext from '../../../contexts/user/UserContext'
import useRoutes from '../../../hooks/useRoutes'
import { useHistory } from 'react-router-dom'
import useLocation from '../../../hooks/useLocation'
import { getTierNameFromNumber, isServerRendering } from '../../../utils/applicationHelper'
import { initiateHostOnboarding } from '../../reusable_components/modals/session/SessionModal'

import Button from '../../reusable_components/buttons/Button'
import DynamicModal from '../../reusable_components/modals/DynamicModal'
import EjblaLogo from '../../reusable_components/svg_icons/EjblaLogo'
import {
	CenteredPageSection,
	CenteredSection,
	PageSection,
	SmallPageSectionDivider,
} from '../../reusable_components/layout/PageComponents'
import { P2 } from '../../reusable_components/Typography'
import UpgradeTier from '../../admin/host/company/UpgradeTier'
import Form from '../../reusable_components/forms/Form'
import InvoiceDetailsModal from '../../reusable_components/modals/InvoiceDetailsModal'

const UpdateUser = gql`
	mutation ($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				role
				tier
			}
			errors {
				path
				message
			}
		}
	}
`

const GetStartedButton = props => {
	const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
	const [invoiceModalIsOpen, setInvoiceModalIsOpen] = useState(false)
	const routes = useRoutes()
	const history = useHistory()
	const { t } = useTranslation()
	const { userState, dispatch } = useContext(UserContext)
	const [updateUserResult, updateUser] = useMutation(UpdateUser)
	const [selectedTier, setSelectedTier] = useState('free')
	const { children, color, primary, tier } = props
	const { user } = userState

	// A new host account was created, so decide if they should enter invoice details or go to host admin
	const initiateNewHostOnboarding = tier => {
		// if (['no_tier', 'free'].includes(tier)) {
		if (true) {
			initiateHostOnboarding()
		} else {
			setInvoiceModalIsOpen(true) // THis doesn't work at the moment, since GetStartedButton will be removed from DOM since user is now host
		}
	}

	const urlParams = isServerRendering() ? null : new URLSearchParams(useLocation().search)
	const upgradeToHost = (input, onSubmitFinished) => {
		// The user is already logged in, but as a user, so upgrade them to host
		const tier = urlParams?.get('tier') && urlParams?.get('tier') !== '3' ? selectedTier : 'no_tier' // Tier = 3 is no_tier
		const variables = {
			input: {
				id: user.id,
				role: 1,
				tier,
			},
		}
		updateUser(variables).then(response => {
			onSubmitFinished(response.error || response?.data?.updateUser, () => initiateNewHostOnboarding(tier))
		})
	}
	const sessionModalDispatchState = tierFromUrl => {
		const tier = tierFromUrl || 'no_tier'
		return {
			type: 'SESSION_MODAL_DISPLAY_STATE',
			data: {
				displayState: 'SIGN_UP',
				tier,
				title: t('copy:sessionModal.signUpTitleHost'),
				message: (
					<>
						<p>{t('copy:sessionModal.signUpSubTitleHost')}</p>
						{tierFromUrl && tier !== 'no_tier' && (
							<>
								<br />
								<UpgradeTier t={t} hideReadMoreLink />
							</>
						)}
					</>
				),
				tools: true,
				callback: (user, callbackOptions) => {
					if (callbackOptions.hostAccountWasCreated) initiateNewHostOnboarding(user.tier)
				},
			},
		}
	}

	useEffect(() => {
		// If the URL contains a tier, open up the session modal automatically for the user
		// This is so we can link users to this site with a specific tier, so they can sign up to that tier
		// Only do this automatically for the primary button so we don't open multiple popups if we have multiple buttons
		if (primary && urlParams.get('tier')) {
			const tierNumber = urlParams.get('tier')
			const tierName = getTierNameFromNumber(tierNumber)
			if (user.signedIn) {
				if (user.isHost) {
					if (tierName === 'no_tier') {
						// The host didn't choose a tier to upgrade to, so just redirect them to host admin
						initiateHostOnboarding(user.publishedPastCourseDatesCount)
					} else {
						// The host chose a tier to upgrade to, so redirect them to the upgrade tier page
						localStorage.setItem('upgradeTier', tierName)
						history.push(routes.admin.host.overview.path + '?tab=1')
					}
				} else {
					setSelectedTier(tierName)
					setUpgradeModalIsOpen(true)
				}
			} else {
				dispatch(sessionModalDispatchState(tierName))
			}
		}
	}, [])

	return (
		<>
			{user?.isHost || (
				<>
					<Button
						data-header-item
						color={color}
						onClick={() => (user.signedIn ? setUpgradeModalIsOpen(true) : dispatch(sessionModalDispatchState(tier)))}
					>
						{children || t('common:buttons.getStarted')}
					</Button>
					<DynamicModal setDisplayModal={setUpgradeModalIsOpen} displayModal={upgradeModalIsOpen}>
						<CenteredSection>
							<EjblaLogo onClick={() => setUpgradeModalIsOpen(false)} />
						</CenteredSection>
						<SmallPageSectionDivider />
						<Form onSubmit={upgradeToHost}>
							{urlParams?.get('tier') && urlParams?.get('tier') !== '3' ? (
								<>
									<UpgradeTier
										t={t}
										setSelectedTier={setSelectedTier}
										selectedTier={selectedTier}
										title={t('admin:upgradeTier.title.choosePricing')}
									/>
									<CenteredPageSection>
										<Button>{t('common:buttons.choose')}</Button>
									</CenteredPageSection>
								</>
							) : (
								<>
									<CenteredSection>
										<P2>{t('copy:ejblaProPage.createToolsAccountTitle')}</P2>
										<br />
										<p>{t('copy:ejblaProPage.createToolsAccountDescription')}</p>
										<PageSection>
											<Button>{t('common:buttons.continue')}</Button>
										</PageSection>
									</CenteredSection>
								</>
							)}
						</Form>
					</DynamicModal>
				</>
			)}
			<InvoiceDetailsModal
				user={user}
				modalIsOpen={invoiceModalIsOpen}
				setDisplayModal={setInvoiceModalIsOpen}
				callback={initiateHostOnboarding}
				hideCloseButton={true}
			/>
		</>
	)
}

export default GetStartedButton

import React, { useEffect } from 'react'
import { initializeSentry, isServerRendering, setApiKeyStore, setManifestLoaded } from './utils/applicationHelper'
import fetch from 'cross-fetch'
import ScreenSizeProvider from './contexts/screen_size/ScreenSizeProvider'
import { cacheExchange, Client, fetchExchange, Provider } from 'urql'
import initializeI18n from './components/reusable_components/middleware/NewExportedI18nextProvider'
import UserProvider from './contexts/user/UserProvider'
import GroupInquiryProvider from './contexts/group_inquiry/GroupInquiryProvider'
import { setRoutes } from './hooks/useRoutes'
import { setLocation } from './hooks/useLocation'
import getCsrfToken from './hooks/getCsrfToken'
import { setWhiteLabel } from './hooks/getWhiteLabel'
// import { setHelpScoutAppIds } from './utils/helpScoutHelper'

//install Graphcache?

initializeSentry()

const App = props => {
	const {
		locale,
		user,
		routes,
		children,
		railsContext,
		whiteLabelIcon,
		helpScoutAppIds,
		googleMapsSdkApiKey,
		adminDestination,
		stripePublishableKey,
		urqlSsr,
		manifestLoaded,
	} = props
	setRoutes(routes)
	setLocation(railsContext)
	setWhiteLabel({ active: !!whiteLabelIcon, icon: whiteLabelIcon })
	setManifestLoaded(manifestLoaded)
	// setHelpScoutAppIds(helpScoutAppIds)
	setApiKeyStore({ GOOGLE_MAPS_SDK: googleMapsSdkApiKey, STRIPE_PUBLISHABLE_KEY: stripePublishableKey })
	const csrfToken = getCsrfToken()
	initializeI18n(locale)
  // Our Cookie consent right now isn't GDPR compatible, so there is not point in showing it. Also, Chrome will soon not allow third party cookies anyways, and iPhone will stop showing "distracting" elements such as cookie popups
  // initializeCookieConsent(locale)
	let urqlUrl = `${railsContext.origin}/${locale}/graphql`
	if (isServerRendering()) urqlUrl = urqlUrl.replace('https://', 'http://')
	const fetchOptions = {
		headers: { 'X-CSRF-Token': csrfToken },
	}
	if (isServerRendering()) fetchOptions.headers.Cookie = `_ejbla_session=${props.ssrOnlyProps.sessionCookie}`
	if (!isServerRendering()) fetchOptions.credentials = 'same-origin'
	const client = new Client({
		url: urqlUrl,
		fetchOptions: () => {
			return fetchOptions
		},
		fetch: fetch,
		suspense: isServerRendering(),
		exchanges: [
			cacheExchange,
			urqlSsr, // Add `ssr` in front of the `fetchExchange`
			fetchExchange,
		],
	})
	if (!isServerRendering() && !window.filterCache) {
		window.filterCache = {} // Initialize the filter cache
	}
	useEffect(() => {
		// Clean up any old modal params
		const url = new URL(location.href)
		if (url.searchParams.get('modal')) {
			url.searchParams.delete('modal')
		}
		window.history.replaceState({}, document.title, url.href)
	}, [])
	return (
		<Provider value={client}>
			<ScreenSizeProvider railsContext={railsContext}>
				<UserProvider user={user} adminDestination={adminDestination} rootPath={routes.course.startPage.path}>
					<GroupInquiryProvider>{children}</GroupInquiryProvider>
				</UserProvider>
			</ScreenSizeProvider>
		</Provider>
	)
}

export default App

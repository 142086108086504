import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from '../../../utils/disableBodyScroll'
import { LargePaddingWrapper } from '../layout/PageComponents'
import Close from '../svg_icons/Close'
import { theme } from '../../../utils/theme'

const CloseButton = styled.div`
	cursor: pointer;
	position: absolute;
	top: ${theme.paddings.XSmall};
	right: ${theme.paddings.XSmall};
`

class OverlayModal extends React.PureComponent {
	constructor(props) {
		super(props)
		this.setModalRef = node => {
			if (this.props.displayModal) {
				// Disable scroll on body behind the the scrollable modal to eliminate bug on mobile
				// where the body can be scrollable through the modal
				disableBodyScroll(node)
			} else {
				// Enable back body scroll if this modal is the only currently open modal when it is being closed
				if (document.getElementsByClassName('ReactModal__Overlay').length === 1) enableBodyScroll(this.modalRef)
			}
			this.modalRef = node
		}
	}

	componentWillUnmount() {
		// Enable back body scroll if this modal is the only currently open modal
		if (document.getElementsByClassName('ReactModal__Overlay').length === 0) enableBodyScroll(this.modalRef)
	}
	render() {
		const { displayModal, onClose, children, hideCloseButton, zIndex = 8998, useDefaultWrapper = true } = this.props
		const customStyles = {
			overlay: {
				position: 'fixed',
				backgroundColor: 'rgba(0, 0, 0, 0.75)',
				overflowY: 'scroll',
				zIndex,
			},
			content: {
				top: '0',
				left: '0',
				right: '0',
				bottom: 'auto',
				transform: 'translate3d(0px, 0px, 0px)',
				maxWidth: '470px',
				position: 'absolute',
				margin: '15px auto',
				padding: '0',
				border: 'none',
				borderRadius: '2px',
			},
		}
		return (
			<Modal
				ariaHideApp={false}
				isOpen={displayModal}
				onRequestClose={onClose}
				style={customStyles}
				overlayRef={this.setModalRef}
			>
				{!hideCloseButton && (
					<CloseButton onClick={onClose}>
						<Close />
					</CloseButton>
				)}
				{useDefaultWrapper ? <LargePaddingWrapper>{children}</LargePaddingWrapper> : children}
			</Modal>
		)
	}
}

export default OverlayModal

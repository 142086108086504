import React, { useState } from 'react'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { theme } from '../../../utils/theme'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import FollowButton from '../../reusable_components/buttons/FollowButton'
import styled from 'styled-components'
import { ScarcityBadge, StyledScarcityBadge } from '../../reusable_components/other/CourseComponents'
import IntersectionObserverWrapper from '../../reusable_components/responsive/IntersectionObserverWrapper'

export const HeaderWrapper = styled.div`
	overflow: hidden;
	position: relative;
	margin-bottom: ${theme.margins.small};
	> ${StyledScarcityBadge} {
		z-index: 2;
		top: unset;
		right: unset;
	}
	> button {
		position: absolute;
		bottom: ${theme.margins.XSmall};
		right: ${theme.margins.XSmall};
		z-index: 2;
	}
	img {
		border-radius: 0;
	}
`
export const TagBadge = styled.div`
	background: ${theme.colors.lightGray};
	border-radius: 20px;
	padding: ${theme.paddings.XSmall};
	box-shadow: 0 0 2px 0 #0000001f;
`
/*const TagsWrapper = styled.div`
	position: absolute;
	z-index: 2;
	top: ${theme.margins.XSmall};
	left: ${theme.margins.XSmall};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	> a:not(:first-child) {
		margin-top: ${theme.margins.XSmall};
	}
`*/

const HeaderSection = ({ id, image, title, tags, closeSideBar, physical, locationPath, scarcity }) => {
	const [showScarcity, setShowScarcity] = useState(false)
	if (!image) return null
	return (
		<HeaderWrapper>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndExcluding}>
				<ResponsiveImage
					imagePath={image}
					type="BIG_VERTICAL_RECTANGLE"
					alt={title}
					sourceWidth={theme.breakpoints.phone.maxInt}
				/>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.range}>
				<ResponsiveImage imagePath={image} type="SQUARE" alt={title} sourceWidth={theme.breakpoints.tablet.maxInt} />
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<ResponsiveImage
					imagePath={image}
					type="BIG_RECTANGLE"
					alt={title}
					sourceWidth={theme.widths.pageMaxWidthIncludingPaddingInt}
				/>
			</Breakpoint>
			{/*	<TagsWrapper>
				{tags.map((tag, key) => (
					<Link to={tag.path} onClick={closeSideBar} key={key}>
						<TagBadge>
							<P4>#{tag.slug}</P4>
						</TagBadge>
					</Link>
				))}
				{physical || (
					// Only show tag with link to filter for online courses since the location displayed is too specific, like "Flen", which we don't have a filtering option for (we only have Stockholm and online)
					<Link to={locationPath} onClick={closeSideBar}>
						<TagBadge>
							<P4>#online</P4>
						</TagBadge>
					</Link>
				)}
			</TagsWrapper>*/}
			{scarcity && (
				<IntersectionObserverWrapper defaultMountedToDom={true} callback={() => setShowScarcity(true)}>
					<ScarcityBadge scarcity={scarcity} show={showScarcity} />
				</IntersectionObserverWrapper>
			)}
			<FollowButton color="WHITE" favouritableType="course" favouritableId={id} showHeart gtmId="GTM_SAVE_WORKSHOP" />
		</HeaderWrapper>
	)
}

export default HeaderSection

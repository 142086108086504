import React, { useReducer } from 'react'
import UserContext from './UserContext'

export const userProviderInitialState = {
	displayState: null,
	confirmedEmail: false,
	emailToBeConfirmed: null,
	confirmEmailAuthenticityToken: null,
	initiateOnboarding: true,
	forceOpen: false,
	adminDestination: null,
	callback: null,
	message: null,
	title: null,
	tooltip: null,
	showPhoneInput: false,
	tier: null,
	tools: false,
	newlyDiscoveredFeatures: {},
}

const UserProvider = ({ children, user, adminDestination, rootPath }) => {
	const reducer = (state, { type, data }) => {
		if (data === undefined) return state
		switch (type) {
			case 'SESSION_MODAL_DISPLAY_STATE':
				return {
					...state,
					...data,
				}
			case 'SET_USER':
				return {
					...state,
					user: data,
					displayState: null,
				}
			case 'PWA':
				return {
					...state,
					pwa: data,
				}
			case 'NEW_FEATURE_DISCOVERED':
				const newlyDiscoveredFeatures = state.newlyDiscoveredFeatures
				newlyDiscoveredFeatures[data] = true
				return {
					...state,
					newlyDiscoveredFeatures,
				}
			case 'NEW_FAVOURITE':
				const favourites = data
				const user = Object.create(state.user)
				user.favourites = favourites
				return {
					...state,
					user,
				}
			default:
				throw new Error()
		}
	}
	let state = { ...userProviderInitialState, user, pwa: { rootPath, openedInPwa: false } }
	if (adminDestination) {
		// The user tried to access admin but was logged out, so force open session modal to make them log in. If they log in they are then redirect to the page they tried to access
		state.forceOpen = true
		state.adminDestination = adminDestination
		state.displayState = 'LOG_IN'
		state.initiateOnboarding = false
	}
	const [userState, dispatch] = useReducer(reducer, state)
	return <UserContext.Provider value={{ userState, dispatch }}>{children}</UserContext.Provider>
}

export default UserProvider

import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'urql'
import UserContext from '../../../contexts/user/UserContext'

const NewTag = styled.span`
	padding: ${theme.paddings.XXSmall} ${theme.paddings.XSmall};
	background: ${theme.colors.darkGray};
	color: ${theme.colors.white};
	text-align: center;
	font-size: ${theme.fontSizes.p5};
	line-height: ${theme.lineHeights.p5};
	margin-left: ${theme.margins.XSmall};
	border-radius: 100px;
	font-weight: normal;
`

const UpdateUser = gql`
	mutation ($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				id
			}
			errors {
				path
				message
			}
		}
	}
`

// A "New" badge indicating a feature is new. When the user explores the new feature, we let the server know, so when the user refresh the page, the "New" badge will disappear
// Either pass featureDiscovered=true to this component to let the server know the feature was discovered, or use the NEW_FEATURE_DISCOVERED-dispatch with the featureId manually
const NewFeatureIndicator = props => {
	const { featureId, skipServerUpdate } = props
	const { userState, dispatch } = useContext(UserContext)
	const user = userState.user
	const discoveredFeatures = user.discoveredFeatures
	const newlyDiscoveredFeatures = userState.newlyDiscoveredFeatures
	const { t } = useTranslation()
	const [updateUserResult, updateUser] = useMutation(UpdateUser)
	const featureDiscovered = newlyDiscoveredFeatures[featureId]

	useEffect(() => {
		// The feature was discovered, so dispatch the featureId to the UserContext, which will in turn trigger the second useEffect which lets the server know
		if (props.featureDiscovered && !featureDiscovered) {
			dispatch({
				type: 'NEW_FEATURE_DISCOVERED',
				data: featureId,
			})
		}
	}, [props.featureDiscovered, featureDiscovered])

	useEffect(() => {
		if (featureDiscovered && !discoveredFeatures?.includes(featureId) && !skipServerUpdate && !user.impersonated) {
			// The feature was discovered, so let the server know
			const variables = {
				input: {
					id: user.id,
					discoveredFeature: featureId,
				},
			}
			updateUser(variables)
		}
	}, [featureDiscovered])
	return discoveredFeatures?.includes(featureId) ? null : <NewTag>{t('shared:userMenu.new')}</NewTag>
}

NewFeatureIndicator.propTypes = {
	featureId: PropTypes.string, // The ID of the feature
	featureDiscovered: PropTypes.bool, // Set to true to let the server know the feature was discovered
	skipServerUpdate: PropTypes.bool, // Set to true to skip the server update. This can be useful if the same NewFeatureIndicator component is in multiple locations at the same time, to prevent the same server update to unnecessarily trigger once for each component
}

export default NewFeatureIndicator

import React, { useContext } from 'react'
import styled from 'styled-components'
import {
	ContentRow,
	ContentSideBarWrapper,
	Divider,
	DynamicPageSectionRow,
	LeftAlignedFlexBox,
	PageSidePaddingWrapper,
	RepulsiveRow,
	SmallPaddingWrapper,
	SmallPageSectionDivider,
} from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import { B3, LeftAlignedUnorderedList, Markdown, P4, TextSpan } from '../../reusable_components/Typography'
// import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { CourseReviewList } from '../../reusable_components/reviews/ReviewList'
import ReadMore from '../../reusable_components/responsive/ReadMore'
import { SmallHostSection } from '../../reusable_components/other/HostSections'
import PurchaseSection from './PurchaseSection'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import HeaderSection from './HeaderSection'
import ImageGallery from './ImageGallery'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import pageScroller from '../../../utils/pageScroller'
import ShareButton from '../../reusable_components/buttons/ShareButton'
import { BeaconAdjuster } from '../../../utils/helpScoutHelper'
import { CourseDetailsRow } from '../../reusable_components/other/CourseComponents'
import IntersectionObserverWrapper from '../../reusable_components/responsive/IntersectionObserverWrapper'
import ScreenSizeContext from '../../../contexts/screen_size/ScreenSizeContext'

const MapImage = styled.img`
	width: 100%;
`
const BreadCrumbsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${theme.margins.small};
`
// Add bottom padding to give room for the PurchaseFooter on mobile/tablet, so it's not in the way of any content on the bottom of the course page
const PurchaseFooterPlaceholder = styled.div`
	padding-top: 150px;
`
const TitleRating = styled.span`
	margin-left: ${theme.margins.XXSmall};
	text-decoration: underline;
	color: ${theme.colors.lightTextColor};
	cursor: pointer;
`
const IncludedWrapper = styled.div`
	margin-top: ${theme.margins.smallInt + theme.margins.XXSmallInt}px;
	> p:first-child {
		margin-bottom: ${theme.margins.XSmallInt + theme.margins.XXSmallInt}px;
	}
`

const CourseContent = ({ course, lazyLoadCourseCards, isOpenedInContentSideBar = true, t, i18n }) => {
	const { screenType } = useContext(ScreenSizeContext)
	const { state, dispatch } = useContext(ContentSideBarContext)
	if (!course) return null
	// const { screenType } = useContext(ScreenSizeContext)
	const whiteLabelIcon = state.whiteLabelIcon
	const courseSideBarIsOpen = state.sideBarIsOpen && state.contentType === 'COURSE'
	let closeSideBar = () => {
		dispatch({ type: 'HIDE_CONTENT', data: {} })
	}
	const {
		title,
		description,
		additionalInfo,
		hostDescription,
		reviewData,
		province,
		scarcity,
		physical,
		duration,
		language,
		refundCutOff,
		googleMapsUrl,
		googleMapImageUrl,
		id,
		addressWithoutCountry,
		host,
		minParticipants,
		maxParticipants,
		keyLearnings,
		included,
		layout,
		directions,
		subCategoryTitle,
		categoryTitle,
		subCategoryPath,
		categoryPath,
		numberOfConsecutiveCourseDates,
		marketplace,
		purchasePolicyUrl,
		numberOfFavourites,
	} = course
	const { rating, count } = reviewData
	return (
		<>
			<ContentSideBarWrapper isOpenedInContentSideBar={isOpenedInContentSideBar}>
				{/*<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
					{!whiteLabelIcon && (
						<BreadCrumbsWrapper>
							<Breadcrumbs>
							<Link to={categoryPath} onClick={closeSideBar}>
								{categoryTitle}
							</Link>
							<CrocodileRight height="16px" width="16px" />
							<Link to={subCategoryPath} onClick={closeSideBar}>
								{subCategoryTitle}
							</Link>
						</Breadcrumbs>
							{isOpenedInContentSideBar || <ShareButton />}
						</BreadCrumbsWrapper>
					)}
				</Breakpoint>*/}
				<h1>{title}</h1>
				<RepulsiveRow>
					<LeftAlignedFlexBox>
						{count ? (
							<div onClick={() => pageScroller('COURSE_PAGE_REVIEW_LIST')}>
								<TextSpan textColor="orange">★</TextSpan>
								<TitleRating>
									{rating}&nbsp;({count})
								</TitleRating>
							</div>
						) : (
							<span>{t('common:workshop.new')}</span>
						)}
						{numberOfFavourites > 1 && (
							<span>·&nbsp;{t('copy:coursePage.favourites', { favourites: numberOfFavourites })}</span>
						)}
					</LeftAlignedFlexBox>
					{isOpenedInContentSideBar || <ShareButton />}
				</RepulsiveRow>
				<br />
			</ContentSideBarWrapper>
			<PageSidePaddingWrapper skipSidePadding={isOpenedInContentSideBar || ['phone', 'tablet'].includes(screenType)}>
				<HeaderSection {...course} closeSideBar={closeSideBar} />
			</PageSidePaddingWrapper>
			<ContentSideBarWrapper isOpenedInContentSideBar={isOpenedInContentSideBar}>
				<CourseDetailsRow
					t={t}
					numberOfDates={numberOfConsecutiveCourseDates}
					duration={duration}
					language={language}
					minParticipants={minParticipants}
					maxParticipants={maxParticipants}
					physical={physical}
					googleMapsUrl={googleMapsUrl}
					addressWithoutCountry={addressWithoutCountry}
				/>
				<Divider />
				<ContentRow>
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
						<h2>{t('copy:coursePage.overview')}</h2>
					</Breakpoint>
					<div>
						<ReadMore maxLines={11}>
							<p>{description}</p>
						</ReadMore>
						{keyLearnings && keyLearnings[0] && (
							<>
								<br />
								<SmallPaddingWrapper style={{ background: theme.colors.lightGray }}>
									<B3>{t('copy:coursePage.keyLearningsTitle')}</B3>
									<LeftAlignedUnorderedList>
										{keyLearnings.map((keyLearning, key) => keyLearning && <li key={key}>{keyLearning}</li>)}
									</LeftAlignedUnorderedList>
									{included && (
										<IncludedWrapper>
											<B3>{t('copy:coursePage.courseIncludes')}</B3>
											<P4>{included}</P4>
										</IncludedWrapper>
									)}
								</SmallPaddingWrapper>
							</>
						)}
						<SmallPageSectionDivider />
						<div>
							<h2>{t('copy:coursePage.courseLayout')}</h2>
							<p>
								{numberOfConsecutiveCourseDates > 1 ? (
									t('copy:coursePage.occasionsAndTotalTime', {
										time: duration,
										occasions: numberOfConsecutiveCourseDates,
									})
								) : (
									<>
										1 {t('common:workshop.session')}, {duration}
									</>
								)}
							</p>
							{layout && (
								<>
									<br />
									<ReadMore maxLines={5}>
										<p>{layout}</p>
									</ReadMore>
								</>
							)}
						</div>
						{additionalInfo && (
							<>
								<SmallPageSectionDivider />
								<div>
									<h2>{t('copy:coursePage.pleaseNote')}</h2>
									<ReadMore maxLines={5}>
										<p>{additionalInfo}</p>
									</ReadMore>
								</div>
							</>
						)}
					</div>
				</ContentRow>
				<Divider />
				<ContentRow title={t('copy:coursePage.ejblaHost')}>
					<SmallHostSection marketplace={marketplace} host={host} t={t} />
					<div>
						<ReadMore maxLines={7}>
							<p>{hostDescription}</p>
						</ReadMore>
					</div>
				</ContentRow>
				{/*{(!isOpenedInContentSideBar || courseSideBarIsOpen) && host.id && (
					<CourseCardsWrapper
						type="expert_similar"
						id={id}
						divider
						title={t('copy:coursePage.similarFrom', { name: host.fullName })}
						linkButtonText={t('common:buttons.allFromExpert')}
						sideBarContent={host}
						paginate={false}
						url={host.url}
						quantity={['phone', 'tablet'].includes(screenType) ? 2 : 4}
						lazyLoad={lazyLoadCourseCards}
						hostsBookingLinkWasUsed={host.hostsBookingLinkWasUsed}
					/>
				)}*/}
				<SmallPageSectionDivider />
				<PurchaseSection
					{...course}
					closeSideBar={closeSideBar}
					courseSideBarIsOpen={courseSideBarIsOpen}
					isOpenedInContentSideBar={isOpenedInContentSideBar}
					i18n={i18n}
					t={t}
					whiteLabelIcon={whiteLabelIcon}
				/>
				{(!isOpenedInContentSideBar || courseSideBarIsOpen) && (
					<>
						{!!count && (
							<div id="COURSE_PAGE_REVIEW_LIST">
								<IntersectionObserverWrapper>
									<CourseReviewList reviewData={reviewData} courseId={id} />
									<Divider />
								</IntersectionObserverWrapper>
							</div>
						)}
						<IntersectionObserverWrapper>
							<ImageGallery title={title} courseId={id} t={t} />
						</IntersectionObserverWrapper>
					</>
				)}
				<Divider />
				<ContentRow>
					<h2>{t('copy:coursePage.informationFromEjbla')}</h2>
					<div>
						<DynamicPageSectionRow
							margins={{
								inner: theme.margins.smallInt,
							}}
						>
							<div>
								<p>{t('copy:coursePage.cancellationPoliciesTitle')}</p>
								<Markdown linkTarget="_blank" textColor="lightTextColor">
									{t('copy:coursePage.cancellationPoliciesText', {
										url: purchasePolicyUrl,
										refundCutOff,
									})}
								</Markdown>
							</div>
						</DynamicPageSectionRow>
					</div>
				</ContentRow>
				{physical && (
					<>
						<Divider />
						<ContentRow title={t('copy:coursePage.infoAboutLocation')}>
							<div>
								<a href={googleMapsUrl} target="_blank" rel="noopener">
									<MapImage src={googleMapImageUrl} />
								</a>
							</div>
							<div>
								<h3>{addressWithoutCountry}</h3>
								<ReadMore maxLines={3}>
									<p>{directions}</p>
								</ReadMore>
							</div>
						</ContentRow>
					</>
				)}
				{/*{(!isOpenedInContentSideBar || courseSideBarIsOpen) && subCategoryTitle && marketplace && !whiteLabelIcon && (
					<IntersectionObserverWrapper>
						<Divider />
						<CourseCardsWrapper
							type="related"
							id={id}
							optimizeRows
							quantity={4}
							lazyLoad={lazyLoadCourseCards}
							title={t('copy:coursePage.similarEjblas')}
						/>
					</IntersectionObserverWrapper>
				)}*/}
				{isOpenedInContentSideBar && (
					<>
						<PurchaseFooterPlaceholder />
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<BeaconAdjuster adjust={courseSideBarIsOpen} source="COURSE_CONTENT" />
						</Breakpoint>
					</>
				)}
				{!isOpenedInContentSideBar && !!whiteLabelIcon && <PurchaseFooterPlaceholder />}
			</ContentSideBarWrapper>
		</>
	)
}

export default CourseContent

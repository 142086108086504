import React, { useContext, useEffect } from 'react'
import axios from 'axios'
import UserContext from '../../../../contexts/user/UserContext'
// import { beaconCaller, getHelpScoutAppIds } from '../../../../utils/helpScoutHelper'
import { useTranslation } from 'react-i18next-new'
import { userProviderInitialState } from '../../../../contexts/user/UserProvider'
import useRoutes from '../../../../hooks/useRoutes'
import { useHistory } from 'react-router-dom'

import EjblaLogo from '../../svg_icons/EjblaLogo'
import { CenteredSection, SmallPageSectionDivider } from '../../layout/PageComponents'
import LoginContent from './LoginContent'
import SignUpContent from './SignUpContent'
import SignUpWithEmailContent from './SignUpWithEmailContent'
import ConfirmEmailContent from './ConfirmEmailContent'
import ForgotPasswordContent from './ForgotPasswordContent'
import FavouriteContent from './FavouriteContent'
import DynamicModal from '../DynamicModal'
import SignUpUserOrHostContent from './SignUpUserOrHostContent'
import getWhiteLabel from '../../../../hooks/getWhiteLabel'
import { getCloudinaryImageUrl } from '../../../../utils/imageHelper'
import { WhiteLabelIconWrapper } from '../../../layout/header/Header'
// import EjblaLogoHostAdmin from '../../svg_icons/EjblaLogoHostAdmin'

// An already existing host logged in
export const initiateHostOnboarding = publishedPastCourseDatesCount => {
	if (publishedPastCourseDatesCount) {
		location = useRoutes().admin.host.overview.url
	} else {
		location = useRoutes().admin.host.progress.url
	}
}

const SessionModal = () => {
	const { userState, dispatch } = useContext(UserContext)
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const routes = useRoutes()
	const { displayState, user, initiateOnboarding, forceOpen, callback, from, tier, tools, adminDestination } = userState
	useEffect(() => {
		if (displayState && forceOpen && !user.signedIn) {
			document.getElementById('HEADER').style.filter = 'blur(3px)'
			document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'blur(3px)'
		}
	}, [forceOpen])
	if (user.signedIn) return null // Don't open it by accident if they are already logged in

	let closeModal = () =>
		forceOpen ||
		dispatch({
			type: 'SESSION_MODAL_DISPLAY_STATE',
			data: userProviderInitialState,
		})
	let onLogin = (user, redirectUrl, hostWantsToUpgradeTier, hostAccountWasCreated) => {
		if (forceOpen) {
			document.getElementById('HEADER').style.filter = 'none'
			document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'none'
		}

		const finishLogIn = () => {
			/*const helpScoutAppIds = getHelpScoutAppIds()
			const currentAppId = Beacon('info').beaconId
			if (user.isUser && currentAppId !== helpScoutAppIds.user) {
				// Current user is a regular user, but the loaded Help Scout App is our host app, so load the user app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.user)
			} else if ((user.isHost || user.isSuperAdmin) && currentAppId !== helpScoutAppIds.host) {
				// Current user is a host, but the loaded Help Scout App is our regular user app, so load the host app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.host)
			}
			// 'identify' starts a session in Help Scout for the user, so they don't need to enter their email and name when interacting with it
			Beacon('identify', { name: user.fullName || '', email: user.email || '' })*/
			if (adminDestination) {
				window.location = adminDestination
			} else if (redirectUrl) {
				window.location = redirectUrl
			}
		}
		// beaconCaller(finishLogIn)
		finishLogIn()
		const callbackOptions = {}
		if (user.isHost) {
			if (hostWantsToUpgradeTier) {
				// The host logged in and wants to upgrade tier, so reroute them to the upgrade tier section in host admin
				dispatch({ type: 'SET_USER', data: user })
				localStorage.setItem('upgradeTier', tier)
				history.push(routes.admin.host.overview.path + '?tab=1')
			} else if (hostAccountWasCreated) {
				// A host account was created
				callbackOptions.hostAccountWasCreated = true
				dispatch({ type: 'SET_USER', data: user })
			} else {
				// An already existing host logged in normally, so forward them to host admin
				// Unless initiateOnboarding = false, meaning the host logged in during another action, e.g. when making a purchase or favouriting something
				if (initiateOnboarding) {
					initiateHostOnboarding(user.publishedPastCourseDatesCount)
				} else {
					dispatch({ type: 'SET_USER', data: user })
				}
			}
		} else {
			dispatch({ type: 'SET_USER', data: user })
		}
		if (callback) {
			callback(user, callbackOptions)
		}
	}

	const authRequest = (response, onSubmitFinished, provider) => {
		const url = `/api/v1/auth/${provider}/callback?initiate_onboarding=${initiateOnboarding && !from}&tier=${tier}`
		axios({
			method: 'post',
			url: url,
			data: response,
		})
			.then(response => {
				const {
					user,
					confirmedEmail,
					emailToBeConfirmed,
					confirmEmailAuthenticityToken,
					initiateOnboarding,
					redirectUrl,
					hostWantsToUpgradeTier,
					hostAccountWasCreated,
				} = response.data
				if (!confirmedEmail) {
					dispatch({
						type: 'SESSION_MODAL_DISPLAY_STATE',
						data: {
							displayState: 'CONFIRM_EMAIL',
							emailToBeConfirmed,
							confirmEmailAuthenticityToken,
							initiateOnboarding,
							hostWantsToUpgradeTier,
							hostAccountWasCreated,
						},
					})
				} else {
					onLogin(user, redirectUrl, hostWantsToUpgradeTier, hostAccountWasCreated)
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	const socialMediaButtonProps = {
		i18n,
		t,
		authRequest,
	}
	const childProps = {
		dispatch,
		t,
		onLogin,
		socialMediaButtonProps,
		...userState,
	}
	const whiteLabel = getWhiteLabel()
	return (
		<DynamicModal displayModal={!!displayState} setDisplayModal={closeModal} hideCloseButton={forceOpen} zIndex={9000}>
			{/*{tools || (
				<>*/}
			<CenteredSection>
				{whiteLabel.active ? (
					<WhiteLabelIconWrapper src={getCloudinaryImageUrl(whiteLabel.icon, 200)} />
				) : (
					<EjblaLogo onClick={closeModal} disableClick={forceOpen} />
				)}
			</CenteredSection>
			<SmallPageSectionDivider />
			{/*	</>
			)}*/}
			{displayState === 'LOG_IN' && <LoginContent {...childProps} />}
			{displayState === 'SIGN_UP_USER_OR_HOST' && <SignUpUserOrHostContent {...childProps} />}
			{displayState === 'SIGN_UP' && <SignUpContent {...childProps} />}
			{displayState === 'SIGN_UP_WITH_EMAIL' && <SignUpWithEmailContent {...childProps} />}
			{displayState === 'CONFIRM_EMAIL' && <ConfirmEmailContent {...childProps} />}
			{displayState === 'FORGOT_PASSWORD' && <ForgotPasswordContent {...childProps} />}
			{displayState === 'FAVOURITE' && <FavouriteContent {...childProps} />}
			{/*{tools && (
				<CenteredSmallPageSection>
					<EjblaLogoHostAdmin />
				</CenteredSmallPageSection>
			)}*/}
		</DynamicModal>
	)
}

export default SessionModal

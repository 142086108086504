import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import UserContext from '../../../contexts/user/UserContext'

const LogoLink = styled(Link)`
	display: flex;
`
const EjblaLogo = ({ fillColor = '#111111', onClick, disableClick, openedInPwa = false }) => {
	const { userState } = useContext(UserContext)
	return (
		<LogoLink to={userState.pwa.rootPath} onClick={onClick} style={{ pointerEvents: disableClick ? 'none' : 'auto' }}>
			<svg width="72" height="35" xmlns="http://www.w3.org/2000/svg">
				<title>ejblalogo</title>
				<text fontFamily="Chap" x="0" y="27" fill={fillColor} fontSize="35" fontStyle="normal" fontWeight="normal">
					ejbla
				</text>
			</svg>
		</LogoLink>
	)
}

export default EjblaLogo
